import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import './Alerts.css';
import './components/ToggleSwitch.css';
import ToggleSwitch from './components/ToggleSwitch';
import { useContext } from 'react';
import { DataContext } from './App';
import loading from './images/loading.svg';

function Alerts({ alerts, history }) {

    const [viewFilter, setViewFilter] = useState(false);
    const dataContext = useContext(DataContext);
    const filter = dataContext.alertFilter;

    if (!filter || !alerts || alerts.length === 0)
        return null;

    const goToAlert = alert => {
        const qs = new URLSearchParams(window.location.search);
        const shipId = qs.get("shipId");
        const token = qs.get("token");

        history.push(`/live/ship/alertDetail/?shipId=${shipId}&alertType=${alert.Type}&alertId=${alert.Id}&token=${token}`);
    }

    const filteredAlerts = () => {

        const newalert = _.filter(alerts, a => {

            switch (a.SubType) {
                case "ComplianceOperable":
                case "ComplianceNotOperable":
                    return filter.Compliance;
                case "UnexpectedData":
                    return filter.Unexpected;
                case "ApproachingArea":
                    return filter.ApproachingArea;
                case "Opportunity":
                    return filter.Opportunity;
                case "Slow Turning":
                        return filter.SlowTurning;
                default:
                    return true;
            }
        })
        //console.log(newalert);
        return newalert;
    }

    const isFilterd = () => {
        return !filter.Compliance || !filter.ApproachingArea || !filter.Unexpected || !filter.Opportunity || !filter.SlowTurning;
    }

    const getSeverityClass = (severity) => {
        switch (severity) {
            case 0: return "nepfont_closed";
            case 1: return "nepfont_acknowledged";
            case 2: return "nepfont_not-managed";
            default: return null;
        }
    }

    return (<div id="alerts">
        {viewFilter ? <AAQSAlertFilter onClose={() => setViewFilter(false)} /> :
            (<React.Fragment>
                <div className="title-content title-border">
                    <div className="title">Alarms/Alerts</div>
                    <span className="iconFilter" onClick={() => setViewFilter(true)}><i className={isFilterd() ? "nepfont_normal-filters" : "nepfont_empty-filters"} /></span>
                </div>
                <ul>
                    {_.orderBy(filteredAlerts(), "Order", "desc").map((alert, index) => <li key={index} onClick={()=>goToAlert(alert)}>
                    <div className="alertContent">
                        <div><i className={getSeverityClass(alert.Severity)}/><span>Type:</span> <span className="alertValue">{alert.Type}</span></div>
                        <div><span>SubType:</span> <span className="alertValue">{alert.SubTypeName}</span></div>
                        <div><span>Date:</span> <span className="alertValue">{alert.Date}</span></div>
                    </div>
                    <div className="arrowButton"><i className="icon-next"></i></div>
                </li>)}
                </ul>
            </React.Fragment>)
        }
    </div>);
}

const AAQSAlertFilter = ({ onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState();
    const dataContext = useContext(DataContext);
    

    useEffect(() => {
        setFilter(dataContext.alertFilter)
    }, []);

    if (!filter) return null;

    const saveFilter = async () => {
        setIsLoading(true);
        await dataContext.saveAAQSAlertFilter(JSON.stringify(filter));
        setIsLoading(false);
        onClose();
    }

    return <div>
        <div className="title-content">
            <span className="iconFilter" onClick={onClose}><i className="icon-close1" /></span>
        </div>
        <div className="switchContainer disabled">
            <ToggleSwitch id="compliance" disabled defaultChecked={filter.Compliance} onChange={() => setFilter({...filter, Compliance: !filter.Compliance})} /><span>AAQS Compliance</span>
        </div>
        <div className="switchContainer disabled">
            <ToggleSwitch id="unexpected" disabled defaultChecked={filter.Unexpected} onChange={() => setFilter({ ...filter, Unexpected: !filter.Unexpected })} /><span>AAQS Sensor out of scale</span>
        </div>
        <div className="switchContainer disabled">
            <ToggleSwitch id="approaching" disabled defaultChecked={filter.ApproachingArea} onChange={() => setFilter({ ...filter, ApproachingArea: !filter.ApproachingArea })} /><span>AAQS Approaching areas</span>
        </div>
        <div className="switchContainer">
            <ToggleSwitch id="opportunity" defaultChecked={filter.Opportunity} onChange={() => setFilter({ ...filter, Opportunity: !filter.Opportunity })} /><span>AAQS Usage Rate</span>
        </div>
        <div className="switchContainer">
            <ToggleSwitch id="slowturning" defaultChecked={filter.SlowTurning} onChange={() => setFilter({ ...filter, SlowTurning: !filter.SlowTurning })} /><span>Engine Slow Turning</span>
        </div>
        <div className="buttonContent">
            <button onClick={saveFilter} disabled={isLoading}>{isLoading && <img width="25" src={loading} alt="loading" />} Save</button>
        </div>
    </div>
}

export default withRouter(Alerts);