import React, { Component, createContext } from 'react';
import { Route, Switch } from "react-router-dom";
import './App.css';
import './nepmobilefont.css';
import './nepfont.css';
import './nauticalset.css';
import loading from './images/loading.svg';
import Unauthorize from './Unauthorize';
import LiveShip from './LiveShip';
import LiveHome from './LiveHome';
import HomeView from './HomeView';
import Profile from './Profile';
import Support from './Support';
import _ from 'lodash';

export const DataContext = createContext({});

class App extends Component {
    constructor(props) {
        super(props);

        window.qstring = window.location.search;
        const qs = new URLSearchParams(window.location.search);
        let subType = "";
        if (qs.has("alertSubType"))
            subType = qs.get("alertSubType");

        const shipId = qs.get("shipId");
        const alertId = qs.get("alertId");
        const alertSubType = subType;
        const token = qs.get("token");

        this.state = {
            shipId,
            alertId,
            alertSubType,
            token,
            user: '',
            homeData: {},
            data: {},
            alertDetails: null,
            selectedSection: '',
            openedPanel: '',
            voyageInfo: {},
            engineStatus: {},
            dgDetail: null,
            dgName: null,
            labelLoad: null,
            shipImage: null,
            isLoadingItinerary: true,
            isFirstLoading: true,
            isMapEnabled: false,
            isLoadingEngine: true,
            isUserAuthorized: true,
            errorObj: {},
            showErrorView: false,
            isOnboard: false,
            isLiveEnable: false,
            isMyShipEnable: false,
            myShipUrl: '',
            recentShipIds: [],
            alertFilter: {
                Compliance: true,
                Unexpected: true,
                ApproachingArea: true,
                Opportunity: true,
            },
            saveAAQSAlertFilter: this.saveAAQSAlertFilter,
        };

        //this.fetchShipData = this.fetchShipData.bind(this);
        this.fetchVoyageInfo = this.fetchVoyageInfo.bind(this);
        this.handleSelectSection = this.handleSelectSection.bind(this);
        this.handleOpenPanel = this.handleOpenPanel.bind(this);
        this.handleEnableMap = this.handleEnableMap.bind(this);
        this.fetchEngineStatus = this.fetchEngineStatus.bind(this);
        this.fetchShipImage = this.fetchShipImage.bind(this);
        this.handleSelectDG = this.handleSelectDG.bind(this);
        this.handleSetDGDetail = this.handleSetDGDetail.bind(this);
        this.saveAAQSAlertFilter = this.saveAAQSAlertFilter.bind(this);

        this.baseUrl = 'https://' + (window.location.host.indexOf('localhost') !== -1 ? 'localhost:44435' : window.location.host);
        this.urlApi = this.baseUrl + '/api/NotificationCenter';
        this.proxyUrl = '/api/Proxy/Wms';
        this.engineStatusSetTimeout = null;
    }

    componentDidMount() {
        this.fetchAAQSAlertFilter();
    }
    handleError = errorObj => {
        let user = "";

        if (errorObj.errorMessage.indexOf("Unauthorized:") !== -1)
            user = errorObj.errorMessage.substring(errorObj.errorMessage.indexOf(":") + 1);

        const isUserAuthorized = !(errorObj.errorMessage === "InvalidToken" || errorObj.errorMessage.indexOf("Unauthorized:") !== -1);

        if (this.state.isFirstLoading || !isUserAuthorized)
            this.setState({ showErrorView: true, isUserAuthorized, isFirstLoading: false, user, errorObj});
    }

    fetchUserProfile = () => new Promise((resolve, reject) => {
        const token = this.state.token;
        const url = this.urlApi + `/UserProfile?token=${token}`;

        fetch(url, {
            credentials: 'include',
            method: 'get'
        })
            .then(response => {
                if (!response.ok)
                    throw Error(response.statusText);

                return response.json();
            })
            .then(data => {
                if (data.errorCode) {
                    this.handleError(data);
                    reject(data.errorMessage);
                }
                else
                    resolve(data);

                if (this.state.isFirstLoading)
                    this.setState({ isFirstLoading: false });

            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
    });

    fetchSupport = () => new Promise((resolve, reject) => {
        const token = this.state.token;
        const url = this.urlApi + `/Support?token=${token}`;

        fetch(url, {
            credentials: 'include',
            method: 'get'
        })
            .then(response => {
                if (!response.ok)
                    throw Error(response.statusText);

                return response.json();
            })
            .then(data => {

                if (data.errorCode) {
                    this.handleError(data);
                    reject(data.errorMessage);
                }
                else
                    resolve(data);

                if (this.state.isFirstLoading)
                    this.setState({ isFirstLoading: false });

            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
    });

    fetchUserData = () => {
        const token = this.state.token;
        const url = this.urlApi + `/UserData?token=${token}`;

        fetch(url, {
            credentials: 'include',
            method: 'get'
        })
            .then(response => {
                if (!response.ok)
                    throw new Error(response.statusText);

                return response.json();
            })
            .then(data => {

                if (data.errorCode)
                    this.handleError(data);
                else
                    this.setState({
                        user: data.UserName,
                        isLiveEnable: data.IsLiveEnable,
                        isMyShipEnable: data.IsMyShipEnable,
                        myShipUrl: data.MyShipUrl,
                        shipId: data.ShipId,
                        isOnboard: data.ShipId && data.ShipId.length > 0,
                        isFirstLoading: false
                    });
            })
            .catch(err => console.log(err));
    }

    fetchAAQSAlertFilter = () => {
        const token = this.state.token;
        const url = this.urlApi + `/GetAAQSAlertFilter?token=${token}`;

        fetch(url, {
            credentials: 'include',
            method: 'get'
        })
            .then(response => {
                if (!response.ok)
                    throw new Error(response.statusText);

                return response.json();
            })
            .then(data => {
                const filter = JSON.parse(data.filter);
                if (_.isEmpty(filter))
                    this.handleError(data);
                else
                    this.setState({
                        alertFilter: filter
                    });
            })
            .catch(err => console.log(err));
    }

    fetchRecentShips = () => {
        const token = this.state.token;
        const url = this.urlApi + `/GetRecentShips?token=${token}`;

        fetch(url, {
            credentials: 'include',
            method: 'get'
        })
            .then(response => {
                if (!response.ok)
                    throw Error(response.statusText);

                return response.json();
            })
            .then(data => {
                if (data.errorCode)
                    this.handleError(data);
                else
                    this.setState({
                        recentShipIds: data
                    });
            })
            .catch(err => console.log(err));
    }

    updateRecentShips = () => {
        const token = this.state.token;
        const url = this.urlApi + `/UpdateRecentShips?token=${token}&ships=${this.state.recentShipIds}`;

        fetch(url, {
            credentials: 'include',
            method: 'GET'
        })
            .then(response => {
                if (!response.ok)
                    throw Error(response.statusText);

                return response.json();
            })
            .then(() => {

            })
            .catch(err => {
                console.log(err);
            });
    }

    saveAAQSAlertFilter = async (filter) => {
        const token = this.state.token;
        const url = this.urlApi + `/SaveAAQSAlertFilter`;

        let data = { token: token, filter };
        var fdata = new FormData();
        for (var p in data) { fdata.append(p, data[p]) }
        await fetch(
            url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json; charset=utf-8',
            },
            body: fdata,
        })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }

                this.setState({ alertFilter: JSON.parse(filter) });
            })
            .catch(err => {
                console.log(err.message);
            });
    }

    fetchHomeData = () => new Promise((resolve, reject) => {
        window.qstring = window.location.search;
        const qs = new URLSearchParams(window.location.search);
        const token = qs.get("token");
        const url = this.urlApi + `/LoadHomeData?token=${token}`;

        fetch(url, {
            credentials: 'include',
            method: 'get'
        })
            .then(response => {
                if (!response.ok)
                    throw Error(response.statusText);

                return response.json();
            })
            .then(data => {
                if (data.errorCode) {
                    this.handleError(data);

                    if (!this.state.isFirstLoading && this.state.isUserAuthorized)
                        resolve({ updateInterval: +this.state.homeData.updateInterval });
                }
                else {
                    data.data.mapUrl = this.baseUrl + this.proxyUrl;
                    this.setState({ homeData: { ...data.data, isOldMapServer: data.isOldMapServer, mapZoomOptions: data.mapZoomOptions, updateInterval: +data.updateInterval }, isFirstLoading: false });
                    resolve({ updateInterval: +data.updateInterval });
                }

            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
    });

    fetchEngineStatus() {
        window.qstring = window.location.search;
        const qs = new URLSearchParams(window.location.search);

        if (!qs.has("shipId"))
            return false;

        const shipId = qs.get("shipId");
        const token = qs.get("token");

        const url = this.urlApi + `/ShipEngineStatus/?shipId=${shipId}&token=${token}`;

        this.setState({ isLoadingEngine: true });

        fetch(url, {
            //credentials: 'include',
            method: 'get'
        })
            .then(response => {
                if (!response.ok)
                    throw Error(response.statusText);

                return response.json();
            })
            .then(data => {
                console.log("Update Engine data");
                if (data.errorCode) {
                    this.handleError(data);
                }
                else
                    this.setState({ engineStatus: { ...data.shipEngineStatus, updateInterval: +data.updateInterval }, isLoadingEngine: false });


                if (this.state.isUserAuthorized) {
                    if (this.engineStatusSetTimeout !== null)
                        clearTimeout(this.engineStatusSetTimeout);

                    if (this.state.selectedSection === "engineStatus") {
                        this.handleSetDGDetail();
                        this.engineStatusSetTimeout = setTimeout(this.fetchEngineStatus, data.updateInterval ? data.updateInterval : this.state.engineStatus.updateInterval);
                    }
                }

            })
            .catch(err => console.log(err));
    }

    fetchShipImage() {
        const qs = new URLSearchParams(window.location.search);

        if (!qs.has("shipId"))
            return false;

        const shipId = qs.get("shipId");
        const token = qs.get("token");
        const url = this.urlApi + `/GetShipImage/?shipId=${shipId}&token=${token}`;

        fetch(url, {
            method: 'get'
        })
            .then(response => {
                if (!response.ok)
                    throw Error(response.statusText);

                return response.json();
            })
            .then(data => {
                this.setState({ shipImage: data.ShipImage });
            })
            .catch(err => console.log(err));
    }

    fetchVoyageInfo() {
        const qs = new URLSearchParams(window.location.search);

        if (!qs.has("shipId"))
            return false;

        const shipId = qs.get("shipId");
        const token = qs.get("token");
        const url = this.urlApi + `/ShipVoyageInfo/?shipId=${shipId}&token=${token}`;

        this.setState({ isLoadingItinerary: true });

        fetch(url, {
            //credentials: 'include',
            method: 'get'
        })
            .then(response => {
                if (!response.ok)
                    throw Error(response.statusText);

                return response.json();
            })
            .then(data => {
                if (data.errorCode) {
                    this.handleError(data);
                }
                else
                    this.setState({ voyageInfo: { ...data.shipVoyage, updateInterval: +data.updateInterval }, isLoadingItinerary: false });

                if (!this.state.isFirstLoading && this.state.isUserAuthorized) {
                    if (this.state.selectedSection === "itinerary")
                        setTimeout(this.fetchVoyageInfo, data.updateInterval ? +data.updateInterval : this.state.voyageInfo.updateInterval);
                }
            })
            .catch(err => console.log(err));
    }

    fetchShipData = () => new Promise((resolve, reject) => {

        window.qstring = window.location.search;
        const qs = new URLSearchParams(window.location.search);
        if (!qs.has("shipId"))
            return false;

        const shipId = qs.get("shipId");
        const alertId = qs.get("alertId");
        const token = qs.get("token");

        const url = this.urlApi + `/LoadShipData?shipId=${shipId}&alertId=${alertId}&token=${token}`;

        fetch(url, {
            credentials: 'include',
            method: 'get'
        })
            .then(response => {
                if (!response.ok)
                    throw Error(response.statusText);

                return response.json();
            })
            .then(data => {
                if (data.errorCode) {
                    this.handleError(data);

                    if (!this.state.isFirstLoading && this.state.isUserAuthorized)
                        resolve({ updateInterval: +this.state.data.updateInterval });
                }
                else {
                    data.mapUrl = this.baseUrl + this.proxyUrl;
                    this.setState({ data: data, isFirstLoading: false });
                    resolve({ updateInterval: +data.updateInterval });
                }
            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
    });

    fetchAlertData = () => new Promise((resolve, reject) => {
        window.qstring = window.location.search;
        const qs = new URLSearchParams(window.location.search);

        if (!qs.has("shipId") || !qs.has("alertId"))
            return false;

        const token = qs.get("token");
        const alertId = qs.get("alertId");
        const shipId = qs.get("shipId");
        const alertType = qs.get("alertType")
        const url = this.urlApi + `/${alertType == 'ENGINE' ? 'LoadEngineAlertData' : 'LoadAlertData'}?shipId=${shipId}&alertId=${alertId}&token=${token}`;
        this.setState({ alertDetails: null });

        fetch(url, {
            credentials: 'include',
            method: 'get'
        })
            .then(response => {
                if (!response.ok)
                    throw Error(response.statusText);

                return response.json();
            })
            .then(data => {
                if (data.errorCode)
                    this.handleError(data);
                else
                    this.setState({ alertDetails: data.alertDetails });

                resolve(true);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
    });

    handleEnableMap() {
        if (this.state.isMapEnabled === false)
            this.setState({ isMapEnabled: true });
    }

    handleSelectSection(section) {
        if (section) {
            this.setState({ selectedSection: section });
            if (section === "engineStatus")
                this.handleOpenPanel("engineStatus");
        }
    }

    handleOpenPanel(section) {
        if (section) {
            if (this.state.openedPanel === section)
                section = "";

            this.setState({ openedPanel: section });
        }
    }

    handleSelectDG(dgName, labelLoad) {
        if (dgName && labelLoad) {
            this.setState({ dgName: dgName, labelLoad: labelLoad }, this.handleSetDGDetail);
        }
    }

    handleSetDGDetail() {
        if (this.state.dgName) {
            const dgList = this.state.dgName.indexOf("GT") != -1 ? this.state.engineStatus.GTList : this.state.engineStatus.DGList;
            for (var idx in dgList) {
                let dg = dgList[idx];
                if (dg.Name === this.state.dgName) {
                    this.setState({ dgDetail: dg });
                    break;
                }
            }
        }
    }

    handleInitLoading = () => {
        this.setState({ isFirstLoading: true})
    }

    addRecentShipId = shipId => {
        let shipIds = [];

        if (this.state.recentShipIds.indexOf(shipId) === -1) {
            shipIds = [...this.state.recentShipIds, shipId];
            if (shipIds.length > 5)
                shipIds = shipIds.splice(1);

            this.setState({ recentShipIds: shipIds}, this.updateRecentShips);
        }

    }

    changeShip = shipId => {
        this.setState({ shipImage: null, voyageInfo: {}, data: {}, alertDetails: null }, () => this.addRecentShipId(shipId));
    }

    render() {
        var loadingContent;

        if (this.state.isFirstLoading)
            loadingContent = <div className="loadingbg"><img src={loading} alt="loading" /></div>;

        if (this.state.showErrorView)
            return (<Unauthorize user={this.state.user} isUserAuthorized={this.state.isUserAuthorized} errorObj={this.state.errorObj} />);

        return (
            <DataContext.Provider value={this.state}>
                <div className="App">
                    {loadingContent}
                    <Switch>
                        <Route exact path='/' render={() => (<HomeView
                            fetchUserData={this.fetchUserData}
                            user={this.state.user}
                            isLiveEnable={this.state.isLiveEnable}
                            isMyShipEnable={this.state.isMyShipEnable}
                            myShipUrl={this.state.myShipUrl}
                            shipId={this.state.shipId}
                            isOnboard={this.state.isOnboard}
                        />)} />
                        <Route path='/profile' render={() => (<Profile fetchUserProfile={this.fetchUserProfile} />)} />
                        <Route path='/support' render={() => (<Support fetchSupport={this.fetchSupport} />)} />
                        <Route path='/live/ship' render={() => (<LiveShip
                            handleInitLoading={this.handleInitLoading}
                            handleOpenPanel={this.handleOpenPanel}
                            handleSelectDG={this.handleSelectDG}
                            handleEnableMap={this.handleEnableMap}
                            openedPanel={this.state.openedPanel}
                            isMapEnabled={this.state.isMapEnabled}
                            fetchShipData={this.fetchShipData}
                            fetchEngineStatus={this.fetchEngineStatus}
                            fetchVoyageInfo={this.fetchVoyageInfo}
                            fetchShipImage={this.fetchShipImage}
                            shipImage={this.state.shipImage}
                            voyageInfo={this.state.voyageInfo}
                            engineStatus={this.state.engineStatus}
                            isLoading={this.state.isLoadingEngine}
                            isFirstLoading={this.state.isFirstLoading}
                            severity={this.state.data.alert ? this.state.data.alert.Severity : '0'}
                            description={this.state.data.alertDetails ? this.state.data.alertDetails.description : 'The Alert has been closed!'}
                            subType={this.state.data.alertDetails ? this.state.data.alertDetails.subType : this.state.alertSubType}
                            selectedSection={this.state.selectedSection}
                            handleSelectSection={this.handleSelectSection}
                            data={{ ...this.state.data, isOldMapServer: this.state.homeData.isOldMapServer }}
                            user={this.state.user}
                            alertSubType={this.state.alertSubType}
                            dgDetail={this.state.dgDetail}
                            labelLoad={this.state.labelLoad}
                            isUserAuthorized={this.state.isUserAuthorized}
                            isLoadingItinerary={this.state.isLoadingItinerary}
                            isLoadingEngine={this.state.isLoadingEngine}
                            isOnboard={this.state.isOnboard}
                            fetchAlertData={this.fetchAlertData}
                            alertDetails={this.state.alertDetails}
                        />)} />
                        <Route path='/live' render={() => (<LiveHome
                            fetchHomeData={this.fetchHomeData}
                            fetchRecentShips={this.fetchRecentShips}
                            data={this.state.homeData}
                            recent={this.state.recentShipIds}
                            changeShip={this.changeShip}
                            handleEnableMap={this.handleEnableMap}
                            handleInitLoading={this.handleInitLoading}
                            isMapEnabled={this.state.isMapEnabled} />)} />
                        </Switch>
                </div>
            </DataContext.Provider>);
    }

}

export default App;